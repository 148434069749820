import Navbar from "../../components/navbar/navbar";
import Header from "../../components/header/header";
import About from "../../components/about/about";
import Contact from "../../components/contact/contact";
import Projects from "../../components/projects/projects";
import Services from "../../components/services/services";
import headerImg from "../../assets/images/header.svg";
import ScrollToTop from "../../hooks/scrollToTop";

const Home = () => {
  const headerProps = {
    title: "MADE TO BUILD",
    secondTitle: "CONSTRUCTION",
    subtitle: "BUILDING DREAMS TO LAST",
    text: "Our goal is to create strong and sustainable buildings and assuring that",
    secondText: "our team will give you the MTB quality needed for your home.",
    img: headerImg,
  };
  return (
    <>
      <ScrollToTop />
      <Navbar />
      <Header {...headerProps} />
      <Projects />
      <Services />
      <About />
      <Contact bgImg={true} />
    </>
  );
};

export default Home;
