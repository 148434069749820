import ContactForm from "../contactForm/contactForm";
import "./contactModal.css";

const ContactModal = (props: any) => {
  const { isOpen, onClose } = props;
  if (!isOpen) return null;

  const handleOverlayClick = (event: any) => {
    if (event.target === event.currentTarget) onClose();
  };

  return (
    <div className="modal-overlay" onClick={handleOverlayClick}>
      <ContactForm closeModal={onClose} />
    </div>
  );
};

export default ContactModal;
