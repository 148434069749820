import "./headerStyle.css";
import { IHeader } from "../../interfaces/header";
import { useState } from "react";
import ContactModal from "../contact/modal/contactModal";

const Header = (props: IHeader) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };
  return (
    <>
      <div style={{ backgroundImage: `url(${props.img})` }} className="header">
        <div className="container-1200 font-16-bold">
          <div className="header-box">
            <div className="font-64-bold">{props.title}</div>
            <div className="font-64-bold">{props.secondTitle}</div>
            <div className="font-32-bold">{props.subtitle}</div>
            <div className="subtext">{props.text}</div>
            <div className="subtext">{props.secondText}</div>
            <div className="pb-1"></div>
            <div className="btn black-btn" onClick={openModal}>
              CONTACT US
            </div>
          </div>
        </div>
      </div>
      <ContactModal isOpen={modalIsOpen} onClose={closeModal} />
    </>
  );
};

export default Header;
