import "./servicesStyle.css";
import serviceImg from "../../assets/images/services.jpg";

const Services = () => {
  return (
    <div className="row off-white-bg">
      <div
        style={{ backgroundImage: `url(${serviceImg})` }}
        className="service-img"
      ></div>
      <div className="service-box">
        <div className="font-20-bold pb-1">OUR SERVICES</div>
        <div className="pb-1">
          Made To Build provides various services and we
          <span className="bold"> enjoy the challenge of </span>
          each project!
        </div>
        <div>Below are some of our services that we provide:</div>
        <div className="service-list">
          <ul>
            <li>Home Remodels</li>
            <li>Basements</li>
            <li>Additions</li>
            <li>Any Many More!</li>
          </ul>
        </div>
        <div className="pt-1">
          <span className="bold">NOTE: </span>We are NOT limited to the items
          listed above so feel free to reach out for any of your specialized
          projects. We love a challenge!
        </div>
      </div>
    </div>
  );
};

export default Services;
