import { Link } from "react-router-dom";
import { FaHome, FaHammer } from "react-icons/fa";
import logo from "../../assets/images/mtb-logo.jpg";
import "./navbarStyle.css";
import ContactModal from "../contact/modal/contactModal";
import { useState } from "react";

const Navbar = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };
  return (
    <>
      <div className="nav">
        <div className="container-1200 row-space-between">
          <Link to="/">
            <img className="nav-img" src={logo} alt="Logo" />
          </Link>
          <div className="row">
            <Link className="nav-link" to="/">
              <FaHome size={24} />
            </Link>
            <Link className="nav-link" to="/projects">
              <FaHammer size={24} />
            </Link>
            <a className="nav-btn btn blue-btn" onClick={openModal}>
              CONTACT US
            </a>
          </div>
        </div>
      </div>
      <ContactModal isOpen={modalIsOpen} onClose={closeModal} />
    </>
  );
};

export default Navbar;
