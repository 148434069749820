import { useRef, useState } from "react";
import "./contactFormStyle.css";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContactForm = (props: any) => {
  const { closeModal } = props;
  const form = useRef<any>();
  const [errorMessage, setErrorMessage] = useState<any>("");

  const notify = () => toast.success("Form Successfully Sent!");

  const sendEmail = (e: any) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "pb-service",
        "mtb-template",
        form.current,
        "user_FLHBRJsIG3yyUO1b8QgB1"
      )
      .then(
        (result) => {
          console.log(result.text);
          notify();
          closeModal();
          setErrorMessage("");
        },
        (error) => {
          console.log(error.text);
          setErrorMessage(
            "We're currently addressing an issue. However, you can reach us at 801-550-1123. Thank you for your understanding."
          );
        }
      );
  };

  return (
    <>
      <form ref={form} onSubmit={sendEmail} className="contact-form">
        <div className="pb-1 font-20">
          <span className="bold">CONTACT</span> US
        </div>
        <label>Name *</label>
        <input type="text" name="name" required />
        <label>Email *</label>
        <input type="email" name="email" required />
        <label>Message *</label>
        <textarea name="message" required />
        <div className="mb-1 error">{errorMessage}</div>
        <button className="btn blue-btn" type="submit">
          SUBMIT
        </button>
      </form>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
};

// const Input = ({ name, placeholder, isTextArea, onChange }: IForm) => {
//   const [input, setInput] = useState<string>("");
//   const inputName = name?.split(" ");

//   const handleChange = (e: any) => {
//     setInput(e.target.value);
//     if (typeof onChange === "function") {
//       onChange({ name: e.target.name, value: e.target.value });
//     }
//   };

//   return (
//     <label className="pb-1">
//       <div className="label">{name}</div>
//       {isTextArea ? (
//         <textarea
//           value={input}
//           onChange={handleChange}
//           name={inputName ? inputName[0].toLocaleLowerCase() : ""}
//           placeholder={placeholder}
//         />
//       ) : (
//         <input
//           type="text"
//           value={input}
//           onChange={handleChange}
//           name={inputName ? inputName[0].toLocaleLowerCase() : ""}
//           placeholder={placeholder}
//         />
//       )}
//     </label>
//   );
// };

export default ContactForm;
