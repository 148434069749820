import addition from "../../../assets/images/additions/0.jpg";
import remodel from "../../../assets/images/remodels/0.jpg";
import basement from "../../../assets/images/basements/0.jpg";
import office from "../../../assets/images/offices/0.jpg";
import retail from "../../../assets/images/retails/0.jpg";

export const projectInfo = [
  {
    id: 0,
    img: addition,
    title: "ADDITIONS",
    subtitle:
      "Transform your dream home with our exceptional ability to construct additions that complements your unique vision.",
    total: 29,
    text: "Expand the horizons of your living space with our talent for seamlessly",
    secondText:
      "integrating stunning additions into your home, tailored to your needs.",
  },
  {
    id: 1,
    img: remodel,
    title: "REMODELS",
    subtitle:
      "Unveil the true potential of your living space through our expert remodeling services, where we breathe new life into your home.",
    total: 29,
    text: "Embark on a transformative journey of reinventing your living space with our ",
    secondText:
      "remodeling expertise, where every corner reflects your personal style and aspirations.",
  },
  {
    id: 2,
    img: basement,
    title: "BASEMENTS",
    subtitle:
      "Take your living space to a new level by crafting basements that provide comfort, functionality, and endless possibilities.",
    total: 9,
    text: "Descend into luxury and comfort with our unrivaled skill in crafting basements,",
    secondText:
      "turning them into extraordinary spaces that redefine modern living.",
  },
  {
    id: 3,
    img: office,
    title: "OFFICES",
    subtitle:
      "Experience the epitome of productivity as we skillfully construct tailored office spaces that inspire success andcollaboration.",
    total: 7,
    text: "Empower your business with tailored office spaces that merge innovative design and",
    secondText:
      "functionality, fostering a productive environment where success thrives.",
  },
  {
    id: 4,
    img: retail,
    title: "RETAILS",
    subtitle:
      "We captivate customers with our mastery in crafting retail spaces that blend design and functionality seamlessly",
    total: 10,
    text: "From vision to reality, we specialize in creating captivating retail stores that",
    secondText:
      "not only attract customers but also elevates shopping into an unforgettable experience.",
  },
];
