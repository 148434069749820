import "./projectStyle.css";
import { ICard } from "../../interfaces/card";
import { Link } from "react-router-dom";
import { projectInfo } from "./allProjects/projectInfo";

const Projects = () => {
  return (
    <div className="container-1200 row-center pt-2 pb-2">
      <Card
        id={projectInfo[0].id}
        img={projectInfo[0].img}
        title={projectInfo[0].title}
        total={projectInfo[0].total}
      />
      <Card
        id={projectInfo[1].id}
        img={projectInfo[1].img}
        title={projectInfo[1].title}
        total={projectInfo[1].total}
      />
      <Card
        id={projectInfo[2].id}
        img={projectInfo[2].img}
        title={projectInfo[2].title}
        total={projectInfo[2].total}
      />
      <Card id={100} />
    </div>
  );
};

const Card = ({ id, img, title, total }: ICard) => {
  return (
    <>
      {img ? (
        <Link to={`/projects/${id}`}>
          <div className="card relative">
            <img className="card-img" src={img} alt={title} />
            <div className="card-message ">
              <div className="font-20-bold">{title}</div>
              <div className="font-16">{total} IMAGES</div>
            </div>
          </div>
        </Link>
      ) : (
        <div className="card-plain relative">
          <div className="card-title">
            <div className="font-20-bold">OUTSTANDING</div>
            <div className="font-20">DETAIL</div>
          </div>
          <div className="pb-1">
            We provide the <span className="bold">best</span> for our customers
            and <span className="bold">pay attention to every detail.</span>
          </div>
          <div className="pb-1">View our work below.</div>
          <Link className="btn blue-btn" to={"/projects"}>
            VIEW PROJECTS
          </Link>
        </div>
      )}
    </>
  );
};

export default Projects;
