import "./aboutStyle.css";
import logo from "../../assets/images/mtb-logo.jpg";
import DefaultSlideshow from "../slideshows/defaultSlideshow";
import useMediaQuery from "../../hooks/useMediaQuery";

const images: any[] = Array.from(Array(4), (_, index) =>
  require(`../../assets/images/about/${index}.jpg`)
);

const About = () => {
  const largeScreen = useMediaQuery("(min-width: 500px");
  return (
    <div className="about black-bg white-txt">
      <div className="row-center">
        <div className="font-20-bold">ABOUT</div>
        <img className="nav-img" src={logo} alt="Logo" />
      </div>
      <div className="about-container relative">
        <div className="off-white-bg black-txt about-txt">
          <div className="row">
            <div className="font-48">25+</div>
            <div className="yrs-exp">
              <div className="font-20">YEARS</div>
              <div className="font-20-bold">EXPERIENCE</div>
            </div>
          </div>
          <div className="font-14 pb-1">
            At MTB, we understand that your dreams deserve the best. So we take
            pride in our ability to turn your ideas into reality.
          </div>
          <div className="pb-1">
            To achieve our goal, we strive to attain highest quality detail and
            meet your satisfaction
          </div>
        </div>
        <div className="slideshow-shift">
          <DefaultSlideshow
            images={images}
            width={largeScreen ? 425 : 265}
            height={275}
          />
        </div>
        <div className="grey-border"></div>
      </div>
    </div>
  );
};

export default About;
