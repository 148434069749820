import Navbar from "../../components/navbar/navbar";
import ScrollToTop from "../../hooks/scrollToTop";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { projectInfo } from "../../components/projects/allProjects/projectInfo";
import Header from "../../components/header/header";
import Services from "../../components/services/services";
import Contact from "../../components/contact/contact";
import { ImageList, ImageListItem } from "@mui/material";
import useMediaQuery from "../../hooks/useMediaQuery";

const Project = () => {
  const medScreen = useMediaQuery("(min-width: 700px");
  const lgScreen = useMediaQuery("(min-width: 1200px");
  const images: any[] = Array.from(Array(5), (_, index) =>
    require(`../../assets/images/headers/${index}.svg`)
  );
  const { pathname } = useLocation();
  const [project, setProject] = useState<any>();
  const [projectImages, setProjectImages] = useState<any[]>([]);

  useEffect(() => {
    const path = pathname.split("/");
    const idString = path[path.length - 1];
    const id = parseInt(idString, 10);
    setProject(projectInfo[id]);

    const images: any[] = Array.from(Array(projectInfo[id].total), (_, index) =>
      require(`../../assets/images/${projectInfo[
        id
      ].title.toLowerCase()}/${index}.jpg`)
    );
    setProjectImages(images);
  }, [pathname]);

  return (
    <>
      <ScrollToTop />
      <Navbar />
      <Header
        title={project?.title}
        text={project?.text}
        secondText={project?.secondText}
        img={images[project?.id] || null}
      />
      <div
        style={{
          maxWidth: 750,
          margin: "0 auto",
          textAlign: medScreen ? "center" : "left",
          padding: "4rem 2rem 2rem 2rem",
        }}
      >
        Dive into our gallery of work below, where each image captures the
        remarkable expertise and attention to detail we bring to every project,
        showcasing the outcomes of our dedication and skill.
        <span className="bold">
          {" "}
          Are you ready to transform your vision into reality?
        </span>{" "}
        Contact us today for a consultation.
      </div>
      <ImageList
        sx={{
          margin: "0 auto 5rem auto",
          width: lgScreen ? 900 : medScreen ? 600 : 300,
          height:
            projectImages.length < 4 && medScreen
              ? 300
              : projectImages.length < 7 && medScreen
              ? 605
              : 700,
        }}
        variant="quilted"
        cols={lgScreen ? 3 : medScreen ? 2 : 1}
        rowHeight={300}
      >
        {projectImages.map((item: any, index: number) => (
          <ImageListItem
            key={`${item}_${index}`}
            cols={item.cols || 1}
            rows={item.rows || 1}
          >
            <img src={item} alt={`IMG_${index}`} loading="lazy" />
          </ImageListItem>
        ))}
      </ImageList>
      <Services />
      <Contact bgImg={false} />
    </>
  );
};

export default Project;
