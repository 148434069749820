import Contact from "../../components/contact/contact";
import Header from "../../components/header/header";
import Navbar from "../../components/navbar/navbar";
import Projects from "../../components/projects/projects";
import Services from "../../components/services/services";
import headerImg from "../../assets/images/projects-header.svg";
import ScrollToTop from "../../hooks/scrollToTop";
import AllProjectsDisplay from "../../components/projects/allProjects/allProjectsDisplay";

const AllProjects = () => {
  const headerProps = {
    title: "PROJECTS",
    subtitle: "BUILDING DREAMS TO LAST",
    text: "Our goal is to create strong and sustainable buildings and assuring that",
    secondText: "our team will give you the MTB quality needed for your home.",
    img: headerImg,
  };
  return (
    <>
      <ScrollToTop />
      <Navbar />
      <Header {...headerProps} />
      <AllProjectsDisplay />
      <Services />
      <Contact bgImg={false} />
    </>
  );
};

export default AllProjects;
