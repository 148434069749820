import "./allProjectsDisplayStyle.css";
import { Link } from "react-router-dom";
import { ISquareCard } from "../../../interfaces/card";
import { projectInfo } from "./projectInfo";

const AllProjectsDisplay = () => {
  return (
    <div className="projects-row">
      {projectInfo
        ? projectInfo.map((project, i) => {
            return (
              <div key={`${project.title}_${i}`}>
                <SquareCard {...project} />
              </div>
            );
          })
        : null}
    </div>
  );
};

const SquareCard = ({ id, img, title, total, subtitle }: ISquareCard) => {
  return (
    <>
      <Link to={`/projects/${id}`}>
        <div className="square-card relative">
          <img className="square-card-img" src={img} alt={title} />
          <div className="square-card-message ">
            <div className="row-space-between">
              <div className="font-20-bold">{title}</div>
              <div className="font-16 mt-1">{total} IMAGES</div>
            </div>
            <div className="square-card-subtitle">{subtitle}</div>
            <div className="btn blue-btn txt-center square-btn">
              VIEW {title}
            </div>
          </div>
        </div>
      </Link>
    </>
  );
};

export default AllProjectsDisplay;
