import "./contactStyle.css";
import contactImg from "../../assets/images/contact.svg";
import ContactForm from "./contactForm/contactForm";

const Contact = (props: { bgImg: boolean }) => {
  return (
    <div
      style={{ backgroundImage: props.bgImg ? `url(${contactImg})` : "" }}
      className="contact"
    >
      <div className="container-1200 row-center align-center">
        <ContactForm />
        <div className={`contact-text ${!props.bgImg ? "off-white-txt" : ""}`}>
          <div className="font-20">
            TURNING YOUR <span className="bold">DREAMS</span>
          </div>
          <div className="font-20 pb-1">
            INTO <span className="bold">REALITY</span>
          </div>
          <div className="pb-1">
            Join us on a journey to create spaces that are not only built to
            last but are Made To Build memories.
          </div>
          <div className="pb-1">Contact us today for a consultation.</div>
          <hr />
          <div className="font-16">
            Call Us Today: <span className="bold">801-550-1123</span>
          </div>
          <div className="font-16">
            Email: <span className="bold">MTBconstruction@outlook.com</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
