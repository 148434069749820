import "./slideshowStyle.css";
import { ISlideshow } from "../../interfaces/slideshow";
import { useState } from "react";

const DefaultSlideshow = (props: ISlideshow) => {
  const { images, height, width } = props;
  const [slideImg, setSlideImg] = useState<number>(0);

  return (
    <div>
      <div style={{ width: width }} className="default-slideshow">
        {images.length > 0
          ? images.map((image, i) => {
              return (
                <img
                  style={{
                    width: width,
                    transform: `translateX(-${slideImg}px)`,
                    height: height,
                  }}
                  className="slide"
                  src={image}
                  alt={`i${i}`}
                  key={`IMG_${i}`}
                />
              );
            })
          : null}
      </div>
      <div style={{ width: width }} className="row-center">
        {images.length > 0
          ? images.map((_, i) => {
              return (
                <div
                  key={`circle_${i}`}
                  className={`circle-btn ${
                    slideImg !== i * width ? "inactive" : null
                  }`}
                  onClick={() => setSlideImg(i * width)}
                ></div>
              );
            })
          : null}
      </div>
    </div>
  );
};

export default DefaultSlideshow;
